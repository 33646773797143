import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import content_api from "../../ApiCallExpress/content_api";
import user_api from "../../ApiCallExpress/user_api";

export default function AreaOfInterest() {
  const errorRef = useRef();
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const [partner_subjects, setPartnerSubjects] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const signupValues = useSelector(
    (state) => state.partnerSignup_store.signupValues
  );
  const [minimumSubjects, setMinimumSubjects] = useState(3);
  useEffect(() => {
    // get_all_partner_subjects();
    user_api
      .get_partner_by_origin(window.location.origin)
      .then((response) => {
        content_api
          .get_all_partner_subjects(response.id)
          .then((response) => {
            setPartnerSubjects(response);
            // console.log(response);
            if (response.length < 3 && response.length > 0) {
              setMinimumSubjects(1);
            } else if (response.length < 1) {
              setMinimumSubjects(0);
            }
          })
          .then((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
    setSelectedSubjects(signupValues.areaOfInterest);
  }, []);

  const disableNext = () => {
    // return selectedSubjects.length < 3;
    // false
    return false;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // const minimumSubjects =
    //   partner_subjects.length < 3 && partner_subjects.length > 0
    //     ? 1
    //     : partner_subjects.length < 1
    //     ? 0
    //     : 3;

    if (selectedSubjects.length < minimumSubjects) {
      setErrorMessage(
        `Please select at least ${minimumSubjects} areas of interest.`
      );
      errorRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      return;
    } else {
      dispatch({
        type: "SET_SIGNUP_VALUE",
        payload: { key: "areaOfInterest", value: selectedSubjects },
      });
      dispatch({
        type: "SET_CURRENT_SIGNUP_STEP",
        payload: 4,
      });
    }
  };
  return (
    <div className="" ref={errorRef}>
      <div className="py-8">
        <div className="flex flex-col md:flex-row justify-start items-center gap-3">
          <p className="text-2xl font-Gilroy-m text-left text-dsblue-100">
            Area of Interest
          </p>
          <p className="text-dsblue-60">( Select multiple )</p>
        </div>
        <p className="text-left text-dsblue-100">
          What areas are you interested in learning more about?
        </p>
        <p className="text-left text-dsblue-100">
          We will recommend relevant content based on your selection.
        </p>
        {errorMessage && (
          <div className="py-1 px-4 mt-4 flex max-w-sm bg-red-200 rounded-xl text-dsblue-100">
            <div className="justify-start">
              <p className={"pt-2 lg:text-lg  font-Gilroy-l"}>
                {errorMessage + " "}
              </p>
            </div>
          </div>
        )}
      </div>

      <div className="">
        <div className="pt-4 ">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 ">
            {partner_subjects.map((subject) => {
              return (
                <button
                  className="p-8 flex flex-col md:flex-row gap-3 bg-white border shadow-sm rounded-xl "
                  onClick={() => {
                    // check if subject is already selected
                    if (selectedSubjects.includes(subject.id)) {
                      setSelectedSubjects(
                        selectedSubjects.filter((id) => id !== subject.id)
                      );
                    } else {
                      setSelectedSubjects([...selectedSubjects, subject.id]);
                    }
                  }}
                >
                  <input
                    type="radio"
                    className="mr-6 rounded-full w-6 h-6"
                    id="chck1"
                    checked={selectedSubjects.includes(subject.id)}
                  />
                  <div className="flex flex-col md:flex-row justify-start items-center gap-6">
                    <img
                      className="w-24 object-contain rounded-t-xl"
                      src={subject.preview_url}
                      alt="Card Image"
                    />
                    <div className="flex flex-col">
                      <div className=" text-left">
                        <h3 className="text-lg font-bold text-gray-800 dark:text-white">
                          {subject.name}
                        </h3>
                        <p className="mt-1 text-gray-500 dark:text-neutral-400">
                          {subject.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </button>
              );
            })}
          </div>

          <div className="pt-8">
            {/* Next */}
            <div className="flex justify-between items-center ">
              <button
                type="button"
                onClick={() => {
                  dispatch({
                    type: "SET_CURRENT_SIGNUP_STEP",
                    payload: 2,
                  });
                }}
                className="border-2 border-violet-100 inline-flex justify-center py-2 px-6 shadow-sm text-sm font-medium rounded-md text-dsblue-100 bg-white hover:bg-dsblue-100 hover:text-white "
              >
                Back
              </button>
              <button
                onClick={handleSubmit}
                disabled={disableNext()}
                className={
                  disableNext()
                    ? "inline-flex justify-center py-2 px-6  shadow-sm text-sm rounded-md text-white bg-violet-100 hover:bg-dsblue-200  grayscale cursor-not-allowed"
                    : "inline-flex justify-center py-2 px-6  shadow-sm text-sm rounded-md text-white bg-violet-100 hover:bg-dsblue-200  "
                }
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
