import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import content_api from "../../ApiCallExpress/content_api";
import user_api from "../../ApiCallExpress/user_api";

export default function Confirmation() {
  const dispatch = useDispatch();
  const signupValues = useSelector(
    (state) => state.partnerSignup_store.signupValues
  );
  const [partner_subjects, setPartnerSubjects] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);

  useEffect(() => {
    // get_all_partner_subjects();
    content_api
      .get_all_partner_subjects(1)
      .then((response) => {
        setPartnerSubjects(response);
      })
      .then((error) => {
        console.log(error);
      });
    setSelectedSubjects(signupValues.areaOfInterest);
  }, []);
  const disableNext = () => {
    var result = false;
    return result;
  };
  return (
    <div className="">
      <div className="py-8">
        <p className="text-3xl font-Gilroy-b  text-center text-dsblue-100">
          Registration Confirmation
        </p>
        <p className="text-left text-dsblue-100 mt-8 font-Gilroy-m">
          Thank you for registering with Wiz Robotics!
        </p>
        <p className="text-left text-dsblue-100">
          We have successfully received your registration. Please review your
          submitted information below:
        </p>
      </div>

      <div className="flex flex-col justify-start gap-8">
        <div>
          <p className="text-2xl font-Gilroy-m text-left text-dsblue-100 pb-3">
            Personal Info
          </p>
          {/* <div className="w-1/2 pt-4">
            <div className="grid grid-cols-2 gap-4">
              <p>Full Name</p>
              <p>{signupValues.name}</p>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <p>Gender</p>
              <p>{signupValues.gender}</p>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <p>Birthdate</p>
              <p>{signupValues.birthdate}</p>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <p>Email</p>
              <p>{signupValues.email}</p>
            </div>
            {signupValues.phone && (
              <div className="grid grid-cols-2 gap-4">
                <p>Phone</p>
                <p>{signupValues.phone}</p>
              </div>
            )}
            <div className="grid grid-cols-2 gap-4">
              <p>Username</p>
              <p>{signupValues.username}</p>
            </div>
          </div> */}
          <div className="text-dsblue-80">
            <div className=" hidden md:block">
              <div className="flex justify-start gap-6">
                <div className="w-1/5 font-Gilroy-m">
                  <p>Full Name</p>
                  <p>Gender</p>
                  <p>Birthdate</p>
                  <p>Email</p>
                  {signupValues.phone && <p>Phone</p>}
                  <p>Username</p>
                </div>
                <div>
                  <p>{signupValues.name}</p>
                  <p>{signupValues.gender}</p>
                  <p>{signupValues.birthdate}</p>
                  <p>{signupValues.email}</p>
                  {signupValues.phone && <p>{signupValues.phone}</p>}
                  <p>{signupValues.username}</p>
                </div>
              </div>
            </div>

            <div className=" block md:hidden">
              <div className="flex justify-start gap-6">
                <div className="w-full font-Gilroy-m">
                  <div className="flex justify-between items-center">
                    <p>Full Name</p>
                    <p>{signupValues.name}</p>
                  </div>
                  <div className="flex justify-between items-center">
                    <p>Gender</p>
                    <p>{signupValues.gender}</p>
                  </div>
                  <div className="flex justify-between items-center">
                    <p>Birthdate</p>
                    <p>{signupValues.birthdate}</p>
                  </div>
                  <div className="flex justify-between items-center">
                    <p>Email</p>
                    <p>{signupValues.email}</p>
                  </div>
                  {signupValues.phone && (
                    <div className="flex justify-between items-center">
                      <p>Phone</p>
                      <p>{signupValues.phone}</p>
                    </div>
                  )}
                  <div className="flex justify-between items-center">
                    <p>Username</p>
                    <p>{signupValues.username}</p>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        {signupValues.guardianName && (
          <div className="text-dsblue-80">
            <p className="text-2xl font-Gilroy-m text-left text-dsblue-100 pb-3">
              Guardian Info
            </p>
            {/* <div className=" pt-4">
              <div className="grid grid-cols-2 gap-4">
                <p>Full Name</p>
                <p>{signupValues.guardianName}</p>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <p>Email</p>
                <p>{signupValues.guardianEmail}</p>
              </div>
              {signupValues.guardianPhone && (
                <div className="grid grid-cols-2 gap-4">
                  <p>Phone</p>
                  <p>{signupValues.guardianPhone}</p>
                </div>
              )}
              <div className="flex gap-4">
                <p>Address</p>
                <p className=" ">
                  {signupValues.guardianAddress.street +
                    signupValues.guardianAddress.plain()}
                </p>
              </div>
            </div> */}

            <div className=" md:block hidden">
              <div className="flex justify-start gap-6">
                <div className="w-1/5 font-Gilroy-m">
                  <p>Full Name</p>
                  <p>Email</p>
                  {signupValues.guardianPhone && <p>Phone</p>}
                  <p>Address</p>
                </div>
                <div>
                  <p>{signupValues.guardianName}</p>
                  <p>{signupValues.guardianEmail}</p>
                  <p>{signupValues.guardianPhone}</p>
                  <p>
                    {signupValues.guardianAddress.street +
                      signupValues.guardianAddress.plain()}
                  </p>
                </div>
              </div>
            </div>
            <div className=" block md:hidden">
              <div className="flex justify-start gap-6">
                <div className="w-full font-Gilroy-m">
                  <div className="flex justify-between items-center">
                    <p>Full Name</p>
                    <p>{signupValues.guardianName}</p>
                  </div>
                  <div className="flex justify-between items-center">
                    <p>Email</p>
                    <p>{signupValues.guardianEmail}</p>
                  </div>
                  {signupValues.guardianPhone && (
                    <div className="flex justify-between items-center">
                      <p>Phone</p>
                      <p>{signupValues.guardianPhone}</p>
                    </div>
                  )}
                  <div className="flex justify-between items-start">
                    <p>Address</p>
                    <p className=" break-words w-2/3 text-right">
                      {signupValues.guardianAddress.street +
                        signupValues.guardianAddress.plain()}
                    </p>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        )}
        <div>
          <p className="text-2xl font-Gilroy-m text-left text-dsblue-100 pb-3">
            Area of Interest
          </p>
          <div className="">
            <div className=" ">
              {partner_subjects.map((subject) => {
                return (
                  <div key={subject.id} className="text-dsblue-80">
                    {selectedSubjects.includes(subject.id) && (
                      //   <div className="px-8 flex flex-col border shadow-sm rounded-xl  text-dsblue-100">
                      <p className="text-left">{subject.name}</p>
                      //   </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="p-4 md:p-12 border border-dsblue-100 rounded-xl text-dsblue-80">
          <p>Please confirm that all the information is correct.</p>
          <p>
            If you need to make any changes, click the "Previous" button below
            to go back and make adjustments.
          </p>
          <p> Otherwise, click "Confirm" to finalize your registration.</p>
        </div>
      </div>
      <div className="pt-4">
        <div className="pt-8">
          {/* Next */}
          <div className="flex justify-between items-center ">
            <button
              type="button"
              onClick={() => {
                dispatch({
                  type: "SET_CURRENT_SIGNUP_STEP",
                  payload: 3,
                });
              }}
              className="border-2 border-violet-100 inline-flex justify-center py-2 px-6 shadow-sm text-sm font-medium rounded-md text-dsblue-100 bg-white hover:bg-dsblue-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Back
            </button>
            <button
              onClick={() => {
                dispatch({
                  type: "SET_CURRENT_SIGNUP_STEP",
                  payload: 5,
                });
                user_api
                  .send_verification_email(
                    signupValues.username,
                    signupValues.email
                  )
                  .then((res) => {
                    // console.log("res", res);
                    if (res.hasOwnProperty("error")) {
                      // setErrorMessage(res.error);
                    } else {
                    }
                  })
                  .catch((error) => {
                    console.log("error", error);
                  });
              }}
              disabled={disableNext()}
              className={
                disableNext()
                  ? "inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-violet-100 hover:bg-dsblue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 grayscale cursor-not-allowed"
                  : "inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-violet-100 hover:bg-dsblue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 "
              }
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
